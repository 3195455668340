<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-2">Show Widget</label>
        <b-form-checkbox
          v-model="section.Show"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-2">Show Title</label>
        <b-form-checkbox
          v-model="section.ShowTitle"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div v-if="section.ShowTitle" class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Title</label>
            <textarea v-model="section.Title" rows="3" type="text" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.TitleColor" label="Color" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.TitleFontSize" label="Font size" />
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between mb-3">
            <label class="mr-2">Facebook</label>
            <b-form-checkbox
              v-model="section.FacebookEnabled"
              switch
            ></b-form-checkbox>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-between mb-3">
            <label class="mr-2">Twitter</label>
            <b-form-checkbox
              v-model="section.TwitterEnabled"
              switch
            ></b-form-checkbox>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-between mb-3">
            <label class="mr-2">LinkedIn</label>
            <b-form-checkbox
              v-model="section.LinkedinEnabled"
              switch
            ></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlignInput from '../Sections/AlignInput'
import ColorInput from '../Sections/ColorInput'
import NumberInput from '../Sections/NumberInput'

export default {
  components: {
    AlignInput, ColorInput, NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>